import React from "react"
import "./Button.scss"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import PropTypes from "prop-types"

//Normal link to page inside website
export function Button({ text, path }) {
  return (
    <Link to={path}>
      <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">{text}</span>
      </button>
    </Link>
  )
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}

//This redirects to a external website
export function ButtonExternal({ text, path }) {
  return (
    <a rel="noreferrer" target="_blank" href={path}>
      <button className="learn-more">
        <span className="circle" aria-hidden="true">
          <span className="icon arrow"></span>
        </span>
        <span className="button-text">{text}</span>
      </button>
    </a>
  )
}
ButtonExternal.propTypes = {
  text: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
}
