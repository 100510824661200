import React from "react"

import { LocalizedLink as Link } from "gatsby-theme-i18n"
import "../styles/footer.css"
import { ButtonExternal } from "../components/Button/Button"
import { useTranslation } from "react-i18next"

export default function Footer() {
  const { t } = useTranslation()

  return (
    <footer className="footer alignVertical">
      <div className="footer_container">
        <div className="footer-grid footer-wrapper">
          <div className="footer-left">
            <p className="footer-title h2-title">Fleur de Lys hotel</p>
            <p className="footer-text footer-text-margin">{t("footer.1")}</p>

            <ButtonExternal
              text={"Boeken"}
              mulish={true}
              path={"https://www.google.com"}
            />
          </div>
          <div className="footer-right">
            <p className="footer-title h2-title">{t("footer.2")}</p>
            <p className="footer-text alignVertical">
              Torhoutsesteenweg 66, 8210 Zedelgem
              <a href="tel:050-700-926">Tel. 050/700 926</a>
              <a href="mailto:info@hotelfleurdelys.be">
                info@hotelfleurdelys.be
              </a>
              <a href="mailto:hotelfleurdelys@telenet.be">
                hotelfleurdelys@telenet.be
              </a>
            </p>
          </div>
        </div>
        <a
          href="https://www.instagram.com/bertmeeuws.io/"
          target="_blank"
          rel="noreferrer"
          className="footer-bertmeeuws footer-wrapper p-mulish"
        >
          {t("footer.8")}
        </a>
        <div className="footer-links footer-wrapper">
          <p className="footer-copyright">2021 - 2022 © Hotel Fleur de Lys</p>
          <ul className="footer-links-list flex-horizontal">
            <li className="footer-links-item">
              <Link to="/">Home</Link>
            </li>
            <li className="footer-links-item">
              <Link to="/rooms">{t("footer.4")}</Link>
            </li>
            <li className="footer-links-item">
              <Link to="/links">{t("footer.5")}</Link>
            </li>
            <li className="footer-links-item">
              <Link to="/contact">{t("footer.6")}</Link>
            </li>
            <li className="footer-links-item">
              <Link to="/">{t("footer.7")}</Link>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  )
}
