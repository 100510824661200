import React from "react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import "../styles/menu.css"
import { useTranslation } from "react-i18next"

export default function Menu() {
  const { t } = useTranslation()

  return (
    <div className="menu">
      <div className="menu-container">
        <ul className="menu-list">
          <li className="menu-list-item">
            <Link to="/">Home</Link>
          </li>
          <li className="menu-list-item">
            <Link to="/rooms">{t("footer.4")}</Link>
          </li>
          <li className="menu-list-item">
            <Link to="/links">{t("footer.5")}</Link>
          </li>

          <li className="menu-list-item">
            <Link to="/contact">{t("footer.6")}</Link>
          </li>
        </ul>
        <div className="menu-contact">
          <p className="footer-text alignVertical">
            Torhoutsesteenweg 66, 8210 Zedelgem
            <a href="tel:050-700-926">Tel. 050/700 926</a>
            <a href="mailto:info@hotelfleurdelys.be">info@hotelfleurdelys.be</a>
            <a href="mailto:hotelfleurdelys@telenet.be">
              hotelfleurdelys@telenet.be
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}
