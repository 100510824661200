/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./Footer"

import "../styles/reset.css"
import "../styles/vars.css"
import "../styles/style.css"
import "../styles/nav.css"
import "../styles/home.css"
import "../styles/rooms.css"
import "../styles/contact.css"
import "../styles/links.css"
import "../styles/detail.css"
import "../styles/lightbox.css"
import { Location } from "@reach/router"

const Layout = ({ children, path }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Location>
      {
        ({ location }) => (
          <>
            <Header location={location} path={path} />
            {children}
            <Footer />
          </>
        )
        // ...
      }
    </Location>
  )
}

export default Layout
