import React, { useEffect, useState } from "react"
import Menu from "./Menu"
import { Squash as Hamburger } from "hamburger-react"
import { LocalizedLink as Link } from "gatsby-theme-i18n"
import Dropdown from "react-dropdown"
import "react-dropdown/style.css"
import { useTranslation } from "react-i18next"
import { navigate } from "gatsby"

const Header = ({ path, location }) => {
  const [offset, setOffset] = useState(0)
  const [menu, setMenu] = useState(false)
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState("NL")

  useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.pageYOffset)
      }
    }
    setLanguage(i18n.options.lng.toUpperCase())
  }, [])

  const getCurrentPath = language => {
    const path = location.pathname
    console.log(path)
    console.log(language)

    const short = location.pathname.substr(0, 4)
    console.log(short)
    let new_path

    if (short === "/en/" || short === "/fr/" || short === "/de/") {
      console.log("Inside")
      if (language === "nl") {
        new_path = path.replace(short, `/`)
      } else {
        new_path = path.replace(short, `/${language}/`)
      }
    } else {
      // /rooms
      new_path = path.replace(location.pathname.substr(0, 1), `/${language}/`)

      console.log(new_path.substr(-1))
    }
    navigate(new_path, { replace: true })
  }

  const changeLanguage = language => {
    const new_language = language.toLowerCase()
    getCurrentPath(new_language)
  }

  const checkIfNavMustBeBlue = () => {
    //This function is ran to decide if the navbar should have blue or white text color.
    //This function is ran insdie the className
    if (
      path === "rooms" ||
      path === "contact" ||
      path === "404" ||
      path === "links"
    ) {
      return true
    }

    if (path === "") {
      return false
    }
  }

  const options = ["NL", "FR", "EN", "DE"]
  const defaultOption = i18n.options.lng.toUpperCase()

  return (
    <>
      {menu ? <Menu path={path} /> : ""}

      <nav
        style={
          offset > 50 && !menu
            ? { backgroundColor: "white", color: "var(--blue)" }
            : {}
        }
        className={`nav ${checkIfNavMustBeBlue() ? "nav-blue" : ""}`}
      >
        <div className="nav-wrapper">
          <div className="nav-left flex-horizontal">
            <Hamburger size={25} toggled={menu} toggle={() => setMenu(!menu)} />
            <div className="translate-box">
              <Dropdown
                className="translate-dropdown-plugin"
                menuClassName="translate-dropdown-options"
                placeholderClassName={`translate-dropdown-placeholder ${
                  checkIfNavMustBeBlue() || offset > 50
                    ? "translate-dropdown-placeholder--active"
                    : ""
                }`}
                controlClassName="translate-dropdown-control"
                options={options}
                onChange={e => {
                  setLanguage(e.value)
                  changeLanguage(e.value)
                }}
                value={defaultOption}
              />
            </div>
          </div>
          <Link to="/">
            <p
              className={`nav-title h1-bold ${
                checkIfNavMustBeBlue() ? "nav-blue" : ""
              }`}
              style={offset > 50 && !menu ? { color: "var(--blue)" } : {}}
            >
              Fleur de Lys
            </p>
          </Link>
          <div className="nav-right flex-horizontal">
            <p
              style={
                checkIfNavMustBeBlue() || offset > 50
                  ? { color: "var(--blue)" }
                  : {}
              }
              className="nav-tel"
            >
              +32 50 700 926
            </p>

            {checkIfNavMustBeBlue() ? (
              <a
                style={
                  offset > 50
                    ? { borderColor: "var(--blue)", color: "var(--blue)" }
                    : {}
                }
                href="https://www.booking.com/hotel/be/fleur-de-lys.nl.html?aid=1288280;label=metagha-link-localuniversalBE-hotel-249724_dev-desktop_los-1_bw-0_dow-Thursday_defdate-1_room-0_lang-nl_curr-EUR_gstadt-2_rateid-0_aud-0_cid-_gacid-6642513561_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20210325;sid=0b08409047913fca6e995e4d5fc38af2;checkin=2021-03-25;checkout=2021-03-26;dest_id=-1973154;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1616691131;srpvid=7de4769d0734014a;type=total;ucfs=1&#no_availability_msg"
                target="_blank"
                rel="noreferrer"
                className="nav-booking-blue p-mulish"
              >
                {t("home.2")}
              </a>
            ) : (
              <a
                style={
                  offset > 50
                    ? { borderColor: "var(--blue)", color: "var(--blue)" }
                    : {}
                }
                href="https://www.booking.com/hotel/be/fleur-de-lys.nl.html?aid=1288280;label=metagha-link-localuniversalBE-hotel-249724_dev-desktop_los-1_bw-0_dow-Thursday_defdate-1_room-0_lang-nl_curr-EUR_gstadt-2_rateid-0_aud-0_cid-_gacid-6642513561_mcid-10_ppa-0_clrid-0_ad-1_gstkid-0_checkin-20210325;sid=0b08409047913fca6e995e4d5fc38af2;checkin=2021-03-25;checkout=2021-03-26;dest_id=-1973154;dest_type=city;dist=0;group_adults=2;group_children=0;hapos=1;hpos=1;no_rooms=1;room1=A%2CA;sb_price_type=total;soh=1;sr_order=popularity;srepoch=1616691131;srpvid=7de4769d0734014a;type=total;ucfs=1&#no_availability_msg"
                target="_blank"
                rel="noreferrer"
                className="nav-booking p-mulish"
              >
                {t("home.2")}
              </a>
            )}
          </div>
        </div>
      </nav>
    </>
  )
}

export default Header
